import { action, computed, observable, makeObservable } from 'mobx';
import Data from './Data';
import { DateTime } from 'luxon'
import { NIGHT_BREAK } from '../App';
import { Feature, FeatureCollection } from '@turf/helpers';
const NIGHTTIME_BREAKPOINT = "9PM"
const NIGHTTIME_HARD_CLOSE = "2AM"

export default class FeaturesModel {


    itemCategoryHeight = 40
    itemCategoryHeightPadding = 5
    
    
    week = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]

    weekdays = [
        "Mon",
        "Tue",
        "Wed",
        "Thur",
        "Fri",
        "Sat",
        "Sun"
    ]

    weekdaymap = {
        "Mon":"Monday",
        "Tue":"Tuesday",
        "Wed":"Wednesday",
        "Thur":"Thursday",
        "Fri":"Friday",
        "Sat":"Saturday",
        "Sun":"Sunday"
    }
    
    source_colums_lookup = {
        "Rice Campus" : "min_campus",
        "Main Street": "min_main",
        "Ion District":"min_ion",
        "Rice Village":"min_village"
    }


    constructor() {
        makeObservable(this);
    }

    @observable
    filterWalkMinsPanel: boolean = true;

    @action
    setFilterWalkMinsPanel(){
        this.filterWalkMinsPanel = !this.filterWalkMinsPanel
    }

    @observable
    filterOpenHoursPanel: boolean = false;

    @action
    setFilterOpenHoursPanel(){
        this.filterOpenHoursPanel = !this.filterOpenHoursPanel
    }

    @observable
    weekday: string = 'Mon';

    @action
    setWeekday(day: string) {
        this.weekday = day;
    }

    @observable
    nighttimeBreakpoint: number = 6;

    @action
    setNighttimeBreakpoint(timebreakpoint: number) {
        this.nighttimeBreakpoint = timebreakpoint;
    }

    @observable
    walk: number  = 10;

    @action
    setWalk(walk: number) {
        this.walk = walk;
    }


    @observable
    sourceIsochrone: string = "Rice Campus";

    @action
    setSourceIsochrone(source: string) {
        this.sourceIsochrone = source;
    }


    @observable
    filterCategory: string[] = [];

    @action
    setFilterCategory(category: string) {
        if (this.filterCategory.includes(category)) {
            this.unsetFilterCategory(category);
        }
        else 
        {
            this.filterCategory?.push(category);
        }
    }

    @action
    unFilterCategory(){
        this.filterCategory = [];
    }

    @action
    unsetFilterCategory(category: string) {
        this.filterCategory?.splice(this.filterCategory.indexOf(category),1);
    }

    @observable
    daySelect: string | number | undefined = 'Mon'

    @action
    setDaySelect(day:string | number){
        this.daySelect = day;
    }

    @observable
    features: any[] = []

    @action
    setFeatures(features: Feature[]) {
        this.features = features
    }

    @computed
    get filteredIsochrone(){
        //@ts-ignore
        return Data.isochrones.features.reduce((features: Feature[], d: Feature) => {
            console.log("Data.isochrones.features", this.sourceIsochrone, d)
            // @ts-ignore
            if (+d.properties.bucket_minute + 1 === +this.walk && d.properties.entryname === this.source_colums_lookup[this.sourceIsochrone].replace("min_","") ) {features.push(d)}
            return features;
        }, [])
    }


    @computed
    get boundary(){

        return Data.boundary;
    }


    @computed
    get neighborhoodCenters(){

        return Data.neighborhood_centers;
    }
    
    @computed
    get neighborhoods(){

        return Data.neighborhoods;
    }

    @computed
    get allCountPerCategories() {
        return Array.from(this.categories).reduce((res, arrVal) => {
            res[arrVal] = [...Object.values(this.allPoints).filter((f: Feature) => {
                return f.properties!.upper_category === arrVal;
            })].length;
            return res;
        }, {})
    }

    @computed
    get countAll() {
        let cnt = 0;
        return Array.from(this.categories).reduce((res, arrVal) => {
            
            res += [...Object.values(this.unCategoryPoints).filter((f: Feature) => {
                return f.properties!.upper_category === arrVal;
            })].length;
            return res;
        }, 0)
    }

    @computed
    get countPerCategories() {
        return Array.from(this.categories).reduce((res, arrVal) => {
            res[arrVal] = [...Object.values(this.unCategoryPoints).filter((f: Feature) => {
                return f.properties!.upper_category === arrVal;
            })].length;
            return res;
        }, {})
    }
    

    @computed
    get categories() {
        return new Set([...Object.values(this.allPoints).filter((f: Feature) => {
            return true;
        }).map((f: any) => { return f.properties.upper_category })])

    }

    @computed
    get filteredPoints(): Feature[] { 
        const _points = (this.filterOpenHoursPanel) ? this.openDayPoints : this.allPoints;
        //@ts-ignore
        const filteredpoints: FeatureCollection | Feature[] = [...Object.values(_points)].filter((f: Feature) => {//@ts-ignore
            // console.log("f.properties![this.source_colums_lookup[this.sourceIsochrone]]", f.properties![this.source_colums_lookup[this.sourceIsochrone]]) //@ts-ignore
            const ifWalk = (this.filterWalkMinsPanel ) ? (f.properties![this.source_colums_lookup[this.sourceIsochrone]] !== null && f.properties![this.source_colums_lookup[this.sourceIsochrone]] + 1 <= this.walk) : true;
            const ifFilterCategory = (this.filterCategory.length > 0) ? this.filterCategory.includes(f.properties!.upper_category) : (this.selectAll) ? false : true;
            const ifHoverSubCatFilter = (this.hoverSubCategoryFilter) ? (f.properties!.type === this.hoverSubCategoryFilter) : true;
            return ifFilterCategory && ifWalk && ifHoverSubCatFilter;
        })
        
        return filteredpoints
    }



    @computed
    get walkDistPoints(): Feature[] { 
        //@ts-ignore
        const filteredpoints: FeatureCollection | Feature[] = [...Object.values(this.allPoints)].filter((f: Feature) => { //@ts-ignore
            const ifWalk = (this.filterWalkMinsPanel ) ? (f.properties![this.source_colums_lookup[this.sourceIsochrone]] !== null && f.properties![this.source_colums_lookup[this.sourceIsochrone]] <= this.walk) : true;
            return ifWalk;
        })
        
        return filteredpoints
    }



    @computed
    get openDayPoints(): Feature[] | FeatureCollection {
        let week_cul_nighthours = 0;
        let weekres: string | any = '';
        const CONSTTIMES: { [key: string]: any } = {
            "Open 24 hours": 1,
            "Closed": 0
        }
        return this.allPoints.filter((f: Feature)=>{ //@ts-ignore
                if (f.properties!.working_hours) { 
                    // console.log(f.properties!.working_hours)
                    if (f.properties!.working_hours instanceof Object) {
                        //@ts-ignore
                        const dayOpenHour = f.properties!.working_hours[this.weekdaymap[this.daySelect]];
                        const formatHour = this.parseAndFormat(dayOpenHour);

                        if (formatHour instanceof Array) {
                            if (formatHour[4] < 0) { week_cul_nighthours = week_cul_nighthours - (formatHour[4]); }
                            weekres = formatHour;
                            return weekres[4] < 0;
                        }
                        else if (dayOpenHour === 'Closed') {
                            return false
                        }
                        else if (dayOpenHour === 'Open 24 hours') {
                            return true
                        }

                    } else {
                        const outs = {};
                        //@ts-ignore
                        f.properties.working_hours.split(", ").forEach((dayhour)=>{
                            const dayhourlist = dayhour.split(":");
                            // console.log("dayhourlist",dayhourlist)
                            // @ts-ignore
                            outs[`${dayhourlist[0]}`] = dayhourlist[1] 
    
                        })
    
                        // console.log("outs",outs)
                           //@ts-ignore
                        // const dayOpenHour = f.properties.working_hours[this.weekdaymap[this.daySelect]];
                        
                        if (outs instanceof Object) {
    
                             //@ts-ignore
                            const dayOpenHour = outs[this.weekdaymap[this.daySelect]];
                            const formatHour = this.parseAndFormat(dayOpenHour);
                        if (formatHour instanceof Array) {
                            if (formatHour[4] < 0) { week_cul_nighthours = week_cul_nighthours - (formatHour[4]); }
                            weekres = formatHour;
                            return weekres[4] < 0;
                        }
                        else if (dayOpenHour === 'Closed') {
                            return false
                        }
                        else if (dayOpenHour === 'Open 24 hours') {
                            return true
                        }
    
    
    
                        }
                    }
                   

                    //@ts-ignore
                    // const dayOpenHour = f.properties.working_hours[this.weekdaymap[this.daySelect]];
                    // console.log("whoursl",whoursl)
                    
     
                } else {
                    return false;
                }
        })
    }

    @observable
    selectAll: boolean = true

    @action
    setSelectAll(selectAll: boolean) {
        this.selectAll = selectAll
    }

    @computed
    get unCategoryPoints(): Feature[]  { 

        const _points = (this.filterOpenHoursPanel) ? this.openDayPoints : this.allPoints;
        //@ts-ignore
        const filteredpoints: FeatureCollection | Feature[] = [...Object.values(_points)].filter((f: Feature) => { //@ts-ignore
            const ifWalk = (this.filterWalkMinsPanel ) ? (f.properties![this.source_colums_lookup[this.sourceIsochrone]] !== null && f.properties![this.source_colums_lookup[this.sourceIsochrone]] < this.walk) : true;
            return ifWalk;
        })
        
        return filteredpoints


    }

    @computed
    get allPoints(): Feature[]  { //@ts-ignore
        return Data.geoPoint.features;
    }


    @observable
    filterSubCategoryCategory: string | undefined;

    @action
    setFilterSubCategoryCategory(category: string | undefined){
        this.filterSubCategoryCategory = category;
    }


    @computed
    get subCategoryPointscountsPerPoints() {
        
        const _points = this.filteredPoints;
        const filteredpoints: FeatureCollection | Feature[] = [...Object.values(_points)].filter((f: Feature) => { //@ts-ignore
            return f.properties.upper_category === this.filterSubCategoryCategory;
        })
        
        const sub_categories = new Set([...Object.values(this.filteredPoints).filter((f: Feature) => {
            return true;
        }).map((f: any) => { return f.properties.type })])


        return Array.from(sub_categories).reduce((res, arrVal) => {
            res[arrVal] = [...Object.values(this.filteredPoints).filter((f: Feature) => {
                return f.properties!.type === arrVal;
            })].length;
            return res;
        }, {})

    }

    @observable
    hoverSubCategoryFilter: string | undefined;


    @action
    setHoverSubCategoryFilter(hoverSubCategoryFilter: string | undefined) {
        this.hoverSubCategoryFilter = hoverSubCategoryFilter;
    }

    @computed
    get hoverSubCatFilterPoints() {
        const _points = this.filteredPoints;
        const filteredpoints: FeatureCollection | Feature[] = [...Object.values(_points)].filter((f: Feature) => { //@ts-ignore
            return f.properties.type === this.hoverSubCategoryFilter;
        })

        return filteredpoints;

    }

    parseAndFormat(vl: any) {
        // console.log("vl",vl)
        const ha_reg = /(?<from>\d{0,2}\:{0,1}\d{1,2})(?<frmmer>\D{0,2})-(?<to>\d{0,2}\:{0,1}\d{1,2})(?<tomer>\D{0,2})/;

        const found_hours = ha_reg.exec(vl);
        // console.log(found_hours)
        if (found_hours && found_hours.groups) {
            let [
                fromTime,
                fromTimeMer,
                toTime,
                ToTimeMer
            ] = [
                    found_hours.groups.from,
                    found_hours.groups.frmmer,
                    found_hours.groups.to,
                    found_hours.groups.tomer
                ];

               
            if (ToTimeMer === "") {
                ToTimeMer = "PM"
            }
            // lets calculate the night time active hours--
            // ##
            // we consider 9pm as the breakpoint-- saying we are calculating the number of hours the place is open after 9pm
            // so what is the diff between the closing hours and the 9pm breakpoint
            // 
            // ##
            // we also consider a hard closing time-- say for the 24hours open places how many hours we should consider? 
            // what is the end of end time we should calculate the night time hours based on?

            let closing_time_clean;

            const breakPointMer = (+this.nighttimeBreakpoint < NIGHT_BREAK) ? "AM" : "PM";

            const night_time = DateTime.fromFormat(`${this.nighttimeBreakpoint}${breakPointMer}`, 'ha')
                // console.log("night_time",night_time)
            if (DateTime.fromFormat(`${toTime}${ToTimeMer}`, 'ha').isValid) {
                closing_time_clean = DateTime.fromFormat(`${toTime}${ToTimeMer}`, 'ha')
            } else if (DateTime.fromFormat(`${toTime}${ToTimeMer}`, 'h:mma').isValid) {

                closing_time_clean = DateTime.fromFormat(`${toTime}${ToTimeMer}`, 'h:mma');
            }//@ts-ignore
            let night_time_hours = night_time.diff(closing_time_clean, ["hours"]).values.hours;

            if (ToTimeMer === "PM" && breakPointMer === "AM") {
                night_time_hours = (night_time_hours + 24) * 1
            }
            else if (ToTimeMer === "AM" && breakPointMer === "AM") {
            }
            else if (ToTimeMer === "AM") {
                night_time_hours = night_time_hours - 24
            }
            return [fromTime, (fromTimeMer === '') ? ToTimeMer : fromTimeMer, toTime, ToTimeMer, night_time_hours]


        }

    }

}

