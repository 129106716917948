import { observer } from 'mobx-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useMemo } from 'react';
import { useFeatureModel } from '../Map/hooks/useFeatureModel';

export interface IMinutesPanelProps {
  children?: React.ReactNode;
}

export default observer(function MinutesPanel({ children }: IMinutesPanelProps) {
  const feature = useFeatureModel();

  const filtered_count = useMemo(() => {
    return feature.walkDistPoints.length;
  }, [feature.walkDistPoints])


  const alls_count = useMemo(() => {
    return feature.filteredPoints.length;
  }, [feature.filteredPoints])

  return (<div className={'MinutesPanel'}>

{(!feature.filterWalkMinsPanel) ? (
      <>
    <div className='infoTextWrapperOff'>
     
        <b>{filtered_count}</b> establishments within
        <span className={`minutes_holder`}> <b>{
          (feature.walk) ? ` ${feature.walk}` : ' 10'}</b> </span> min walk


    </div>
     {children}
</>) : <>

<div className='minutesWrapper'>
      <Slider
        min={2}
        max={20}
        value={feature.walk} onChange={(val: number | number[]) => {
          // @ts-ignore
          feature.setWalk(val)
        }} />
    </div>   
    <div className='infoTextWrapper'>
     
        <b>{alls_count}</b> establishments within
        <span className={`minutes_holder`}> <b>{
          (feature.walk) ? ` ${feature.walk}` : ' 10'}</b> </span> min walk


    </div>

     
    </> }
   </div>)
});
