import { observer } from "mobx-react";

import MapWrapper from "../src/components/Map/MapWrapper";
import TitleComp from "../src/components/TitleComp/TitleComp";
import { useCallback, useEffect, useState } from "react";
import MapLegend from "../src/components/MapLegend/MapLegend";
import MinutesPanel from "./components/MapLegend/MinutesPanel";
import CategoriesPanel from "../src/components/MapLegend/CategoriesPanel";
import LegendWrapper from "../src/components/MapLegend/LegendWrapper";
import { useFeatureModel } from "./components/Map/hooks/useFeatureModel";
import InfoModal from "./components/InfoModal/InfoModal";
import { Header } from "@strategies/ui";
import { RadioSlider } from "@strategies/react-form";
import Map, {
    MapProvider,
    NavigationControl,
    FullscreenControl,
    MapLayerMouseEvent,
    useMap,
    SkyLayer,
    Source, Layer
} from "react-map-gl";

import { MapboxExportControl, Size, PageOrientation, Format, DPI} from "@watergis/mapbox-gl-export";
import '@watergis/mapbox-gl-export/css/styles.css';

import CircleFeatures from "./components/Map/CircleFeatures";
import PopUpComp from "./components/Map/PopUpComp";
import BoundaryFeature from "./components/Map/BoundaryFeature";
import HoursPanel from "./components/MapLegend/HoursPanel";
import { FiList, FiRadio, FiClock, FiMaximize } from "react-icons/fi";
import WalkshedOriginPanel from "./components/MapLegend/WalkshedOriginPanel";
import ExportComp from "./components/Map/ExportComp/ExportComp";
import NeighborhoodFeature from "./components/Map/NeighborhoodFeature";
import CategoryBreakdownPanel from "./components/MapLegend/CategoryBreakdownPanel";
import CircleFeaturesFiltered from "./components/Map/CircleFeaturesFiltered";

export const NIGHT_BREAK = 6;
export enum WEEK_DAYS {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THUR = 'Thur',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun'
}

export enum WALKSHEDSOURCELOOKUP {
        MIN_CAMPUS="Rice Campus",
        MIN_MAIN="Main Street",
        MIN_ION="Ion District",
        MIN_VILLAGE="Rice Village",
}

const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };


export default observer(function App() {
    const featuresModel = useFeatureModel();
    const [hover, setHover] = useState(null);
    const [mStyle, setMstyle] = useState<string>("mapbox://styles/mapbox/light-v10")
    const { pulseMap } = useMap();

    const onHover = useCallback((event: MapLayerMouseEvent) => {
        const {
            features,
            point: { x, y },
        } = event;
        const hoveredFeature = features && features[0];
        //@ts-ignore
        setHover(hoveredFeature && { feature: hoveredFeature, x, y });
    }, []);

    useEffect(() => {
        // @ts-ignore
        window.featureModel = featuresModel;
    }, [featuresModel]);




   

    return (
        <div className={"App"}>
            <MapWrapper>
                <MapProvider>
                    <Map
                        id="pulseMap"
                        initialViewState={{
                            latitude: 29.719121,
                            longitude: -95.403526,
                            zoom: 13,
                        }}
                        style={{ width: "100hw", height: "100vh" }}
                        mapStyle={mStyle}
                        styleDiffing
                        mapboxAccessToken={
                            "pk.eyJ1IjoidGFkaXJhbWFuIiwiYSI6IktzUnNGa28ifQ.PY_hnRMhS94SZmIR2AIgug"
                        }
                        terrain={{source: 'mapbox-dem', exaggeration: 1.5}}
                        interactiveLayerIds={["vis-places"]}
                        onMouseMove={onHover}
                        
                    >
                        <Source
                            id="mapbox-dem"
                            type="raster-dem"
                            url="mapbox://mapbox.mapbox-terrain-dem-v1"
                            tileSize={512}
                            maxzoom={14}
                            />
                            <Layer {...skyLayer} />
                        <NavigationControl position="top-left" />
                        <FullscreenControl position="top-left" />
                        <NeighborhoodFeature/>
                        <BoundaryFeature />
                        <CircleFeatures />
                        {/* {featuresModel.hoverSubCatFilterPoints && <CircleFeaturesFiltered />} */}
                        {/* <CircleFeaturesFiltered /> */}



                        {hover && <PopUpComp feature={hover}></PopUpComp>}
                    </Map>
                    <ExportComp/>
                </MapProvider>
                <TitleComp></TitleComp>
                <LegendWrapper>
                    <MapLegend title={"Walk Distance"} toggleIcon={<FiRadio />}>
                        <Header>
                            <div className="panel-selector">
                                <RadioSlider
                                    value={
                                        featuresModel.filterWalkMinsPanel
                                            ? "ON"
                                            : "OFF"
                                    }
                                    options={["ON", "OFF"]}
                                    onChange={() =>
                                        featuresModel.setFilterWalkMinsPanel()
                                    }
                                />
                            </div>
                        </Header>
                        <MinutesPanel />
                    </MapLegend>
                    <MapLegend title={"Walkshed Origin"} toggleIcon={<FiMaximize />}>
                        <Header>
                          
                        </Header>
                        <WalkshedOriginPanel>
                        <div className="panel-source-selector">
                                <RadioSlider
                                    value={(featuresModel.sourceIsochrone) ? featuresModel.sourceIsochrone : ''}
                                    options={
                                        [
                                            WALKSHEDSOURCELOOKUP.MIN_CAMPUS, 
                                            WALKSHEDSOURCELOOKUP.MIN_MAIN, 
                                            WALKSHEDSOURCELOOKUP.MIN_ION, 
                                            WALKSHEDSOURCELOOKUP.MIN_VILLAGE, 
                       
                                        ]
                                    }
                                    onChange={(source) => featuresModel.setSourceIsochrone(source as string)}
                                />
                                </div>
                            </WalkshedOriginPanel>
                    </MapLegend>
                    <MapLegend title={"Open Hours"} toggleIcon={<FiClock />} >
                        <Header>
                            <div className="panel-selector">
                            <RadioSlider
                                value={(featuresModel.filterOpenHoursPanel) ? "ON" : "OFF"}
                                options={["ON", "OFF"]}
                                onChange={() => featuresModel.setFilterOpenHoursPanel()}
                            />
                            </div>
                        </Header>
                        <HoursPanel >
                            <div className="panel-day-selector">
                            <RadioSlider
                                value={(featuresModel.daySelect) ? featuresModel.daySelect : ''}
                                options={[WEEK_DAYS.MON, WEEK_DAYS.TUE, WEEK_DAYS.WED, WEEK_DAYS.THUR, WEEK_DAYS.FRI, WEEK_DAYS.SAT, WEEK_DAYS.SUN]}
                                onChange={(day) => featuresModel.setDaySelect(day)}
                            />
                            </div>
                        </HoursPanel>
                    </MapLegend>
                    <MapLegend title={"Map Legend"} toggleIcon={<FiList />}>
                        <Header>
                          
                        </Header>
                        <CategoriesPanel />
                    </MapLegend>

                  
                </LegendWrapper>
                <LegendWrapper>


                         {(featuresModel.filterCategory.length !== 0) && <MapLegend title={`${featuresModel.filterCategory.slice(0,2).join(", ")}`+`${(featuresModel.filterCategory.length > 2) ? " ..." : ""}`} toggleIcon={<FiList />}>
                        <Header>
                          
                        </Header>
                        <CategoryBreakdownPanel />
                    </MapLegend>}
               
                </LegendWrapper>
                <InfoModal />
            </MapWrapper>
        </div>
    );
});
